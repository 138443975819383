<template>
    <div class="bg-slightGrey">
        <div class="width-90 paddingLr90 bg-white">
            <div class="flex-column justify-content-center align-items-center width-25 margin-t">
                <p class="fs-big blue fw-mid lineHeight">{{ identity === IDENTITY_MATCHER ? '我的' : '' }}巡查</p>
                <img width="28px" src="@/assets/image/icon13.png" alt="" class="margin-bs" />
            </div>
        </div>
        <div v-if="paramsShow" class="width-100 van-dropdown-menu">
            <van-dropdown-menu active-color="#007AFF">
                <van-dropdown-item v-if="isGlobal" v-model="paramsArea" :options="paramsAreaOptions" @change="handleAreaChange" />
                <van-dropdown-item v-model="paramsWorkStatus" :options="paramsWorkStatusOptions" @change="handleStatusPick" />
                <van-dropdown-item :title="rematchTimeRangeTitle" ref="rematchTimeRangeDrop" @open="rangeShow = true" @close="rangeShow = false">
                    <van-calendar
                        v-model="rangeShow"
                        type="range"
                        position="bottom"
                        @confirm="handlerematchTimeRangePick"
                        @close="
                            $refs.rematchTimeRangeDrop.toggle(false)
                            rangeShow = $refs.rematchTimeRangeDrop.showPopup
                        "
                        color="#1989fa"
                        :min-date="new Date('2021/1/1')"
                    >
                        <template slot="title">
                            <van-button type="danger" round class="clear-range-button" @click="handleRangeClear">清除选择</van-button>
                        </template>
                    </van-calendar>
                </van-dropdown-item>
            </van-dropdown-menu>
        </div>
        <div class="width-90 paddingLr90" style="overflow-y: scroll" :style="{ height: `${innerHeight - 168}px` }">
            <van-pull-refresh class="minHeight" v-model="isLoading" @refresh="onRefresh">
                <!-- 列表start -->
                <div
                    v-for="item in list"
                    :key="item.index"
                    class="br-reg van-overflow-hidden bg-white width-100 boxShadow margin-t"
                    style="padding-bottom: 10px"
                >
                    <div class="titBg border-b margin-bs flex-row justify-content-spaceBetween align-items-center">
                        <div class="width-40 flex-row justify-content-start align-items-center">
                            <img width="4px" height="24px" :src="require('@/assets/image/icon1.png')" alt="" />
                            <p class="fs-big black fw-mid margin-l">巡查记录</p>
                        </div>
                        <p class="fs-sml width-60 textAlign-r darkgrey">
                            <span v-if="!item.is_well" :class="[item.rectified ? 'darkgrey' : 'red']"
                                >【{{ item.rectified ? '已整改' : '未整改' }}】</span
                            >
                            <span>{{ item.is_well ? '施工正常' : '施工异常' }}</span>
                        </p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">巡查人员：</p>
                        <p class="width-70 textAlign-r black">{{ item.rematch_user_name }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">巡查时间：</p>
                        <p class="width-70 textAlign-r black">{{ dateFormat(item.match_time) }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">申请单位：</p>
                        <p class="width-70 textAlign-r black">{{ item.enterprise }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">挖掘地段：</p>
                        <p class="width-70 textAlign-r black">{{ item.location }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">施工情况：</p>
                        <p class="width-70 textAlign-r black pre-line">
                            {{ item.is_well ? '施工正常' : item.work_status === '' ? '无' : item.work_status }}
                        </p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">其他情况：</p>
                        <p class="width-70 textAlign-r black">{{ item.other_return ? item.other_return : '无' }}</p>
                    </div>
                    <div class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start">
                        <p class="width-30">后续处理：</p>
                        <p class="width-70 textAlign-r black">{{ ['就地解决', '上报辖区中队', '上报秩序中队', '上报大队'][item.report_level] }}</p>
                    </div>
                    <div
                        v-if="item.images.length > 0 || true"
                        class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start"
                    >
                        <p class="width-30">巡查图片：</p>
                    </div>
                    <div
                        v-if="item.images.length > 0 || true"
                        class="width-90 flex-row flex-wrap justify-content-start align-items-center paddingLr90 margin-ts margin-b"
                    >
                        <van-image
                            v-for="(image, imgIndex) in item.images"
                            @click="preview(item.images, imgIndex)"
                            :key="imgIndex"
                            :src="mediaUrl + image.src"
                            width="72"
                            height="72"
                            fit="cover"
                            class="margin-r"
                        />
                        <van-image-preview v-model="show" :images="images" :start-position="previewIndex"> </van-image-preview>
                    </div>
                    <div
                        v-if="!item.is_well && item.rectified"
                        class="width-90 fs-sml grey paddingLr90 margin-t flex-row justify-content-spaceBetween align-items-start"
                    >
                        <p class="width-30">整改说明：</p>
                        <p class="width-70 textAlign-r black">{{ item.rectify_explain }}</p>
                    </div>
                    <!-- 已整改 -->
                    <div v-if="!(item.is_well || item.rectified)" class="textAlign-c margin-t">
                        <van-button type="info" @click="handleShowRectifyExplainInput">提交整改说明</van-button>
                    </div>
                    <van-dialog
                        v-model="showSubmitRectifyExplain"
                        title="整改完成"
                        show-cancel-button
                        @confirm="handleSubmitRectifyExplainInput(item.id)"
                    >
                        <van-field v-model="rectifyExplain" type="textarea" placeholder="填写完成整改说明（可选）" />
                    </van-dialog>
                </div>
                <!--列表end-->
                <van-empty v-if="list.length === 0" description="暂时没有内容哦~" />
            </van-pull-refresh>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: '',
    components: {},
    data() {
        return {
            show: false,
            index: 0,
            images: ['https://img01.yzcdn.cn/vant/apple-1.jpg', 'https://img01.yzcdn.cn/vant/apple-2.jpg'],
            previewIndex: 0,
            isLoading: false,

            paramsShow: true,
            // navTitle: '',
            paramsArea: null,
            paramsAreaOptions: [],

            paramsWorkStatusOptions: [
                { text: '全部情况', value: null },
                { text: '施工正常', value: true },
                { text: '施工异常', value: false }
            ],
            paramsWorkStatus: null,

            rangeShow: false,
            rematchTimeRangeTitle: '巡查日期',

            params: {},
            count: 0,
            list: [],

            identity: null,
            isGlobal: null,

            showSubmitRectifyExplain: false,
            rectifyExplain: ''
        }
    },
    created() {
        this.$store.state.tabbarIndex = this.TAB_REMATCH
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.identity = userInfo.identity
        this.isGlobal = userInfo.is_global
        // 初始化查询参数
        if (userInfo.identity === this.IDENTITY_MATCHER) {
            this.params.project__rematch_user = userInfo.dd_user_id
        }
        if (this.isGlobal) {
            axios.get('/api/supervision/area/').then((res) => {
                this.paramsAreaOptions = res.data.map((area) => ({ value: area.id, text: area.text }))
                this.paramsAreaOptions.find((area) => area.text === '全市').value = null
            })
        } else {
            this.params.project__information__area = userInfo.area
        }
        const { id, is_well, project__information__submit_unit } = this.$route.query
        if (id) {
            this.params.id = id
            this.paramsShow = false
        }
        if (project__information__submit_unit) {
            this.params.project__information__submit_unit = project__information__submit_unit
            this.paramsShow = false
        }
        if (is_well) {
            this.params.is_well = is_well
            this.paramsShow = false
        }
        this.getData()
    },
    mounted() {},
    methods: {
        getData() {
            let url = '/api/supervision/rematch_list/'
            axios
                .get(url, {
                    params: this.params
                })
                .then((res) => {
                    this.count = res.data.count
                    this.list = res.data.results
                })
        },
        onRefresh() {
            this.getData()
            this.$toast('刷新成功')
            this.isLoading = false
        },
        preview(images, previewIndex) {
            this.show = true
            this.images = images.map((image) => this.mediaUrl + image.src)
            this.previewIndex = previewIndex
        },
        handleAreaChange(area) {
            this.params.project__information__area = area
            this.getData()
        },
        handlerematchTimeRangePick(dates) {
            this.$refs.rematchTimeRangeDrop.toggle()
            const [rangeBegin, rangeEnd] = dates

            let dateFormat = 'YYYY-M-D'
            let beginFormat = this.dateFormat(rangeBegin / 1000, dateFormat + ' 0:0')
            let endFormat = this.dateFormat(rangeEnd / 1000, dateFormat + ' 23:59')
            this.params.match_time__range = beginFormat + ',' + endFormat

            dateFormat = 'M/D'
            beginFormat = this.dateFormat(rangeBegin / 1000, dateFormat)
            endFormat = this.dateFormat(rangeEnd / 1000, dateFormat)
            this.rematchTimeRangeTitle = beginFormat + '-' + endFormat
            this.getData()
        },
        handleStatusPick(value) {
            this.params.is_well = value
            this.getData()
        },
        handleRangeClear() {
            this.$refs.rematchTimeRangeDrop.toggle()
            this.params.match_time__range = null
            this.rematchTimeRangeTitle = '巡查日期'
            this.getData()
        },
        handleShowRectifyExplainInput() {
            this.showSubmitRectifyExplain = true
        },
        handleSubmitRectifyExplainInput(rematchId) {
            const url = '/api/supervision/clear_rematch/'
            axios
                .post(url, {
                    id: rematchId,
                    explain: this.rectifyExplain
                })
                .then((res) => {
                    if (res.data.code !== 0) {
                        this.$dialog.alert({
                            message: '系统错误，请联系管理员。'
                        })
                    } else {
                        this.getData()
                    }
                })
                .catch(() => {
                    this.$dialog.alert({
                        message: '网络错误，请检查连接并重新尝试'
                    })
                })
        }
    }
}
</script>

<style lang="less" scoped>
.van-dropdown-menu {
    height: 48px;
    overflow: hidden;
}
.van-button {
    height: 36px;
}
.clear-range-button {
    width: 50%;
}
.titBg {
    padding: 15px 20px 15px 0;
}
</style>
